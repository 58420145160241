
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import * as types from '@/store/modules/common/mutation-type'
import { OpenType } from '@/enum'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useOpenInfo from '@/hooks/openInfo'
import { setApptitle } from '@/utils/native-app'
import { CustomDialog } from '@/components/CustomDialog'
import { mutateFn, queryFn } from '@/graphql'
import * as storage from '@/utils/storage'
import { Message } from '@/components/Message'
import useCheckIp from '@/hooks/useCheckIp'

export default defineComponent({
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const type = ref()
    const actionType = ref()
    // 保存步骤信息
    const { title } = useOpenInfo()

    const mainland = computed(() => OpenType.MAINLAND)
    const overseas = computed(() => OpenType.OVERSEAS)
    const userInfo = storage.get('userInfo') as any
    const formTypeVal = storage.get('formType') || route.query.type
    const formType = storage.get('formType') as any
    const { isCn } = useCheckIp()
    // 点击跳转大陆身份证以及海外身份证开户

    const handleClick = (openType: number) => {
      if (isCn.value) {
        // IP地址弹窗
        return CustomDialog({
          title: '您当前位于：中国内地',
          confirmText: '我知道了',
          content:
            '非常抱歉，目前无法为中国境内投资者提供开户服务。如有任何查询，请致电 (852) 2153 3838 或电邮至cs@igoldhorse.com与客户服务部查询。'
        }).then(() => {
          //
        })
      }
      if (openType === 1) {
        return CustomDialog({
          cancelText: '取消',
          content:
            // eslint-disable-next-line max-len, max-len
            '<p style="text-align:left;line-height: 1.5;">鉴于中国证券监督管理委员会、上海证券交易所、深圳证券交易所发布了有关限制内地投资者参与沪深股通交易的规则修订，由2022年7月25日（星期一）起，金马证券<span style="font-weight:800;border-bottom:2px solid #bfc7de">不能</span>再为持有中国内地身份证明文件的内地投资者开通新的⎡券商客户编码⎦（俗称BCAN）。</p><p style="line-height: 1.5;text-align:left;margin-top: 30px;">请注意: 客户买卖北向交易的中华通证券需持有券商客户编码。如有任何疑问, 请致电 (852) 21533838 与客户服务部联络。</p>'
        })
          .then(() => {
            mutateFn('clientOperationRecord', {
              params: {
                tradeAccount: userInfo.clientId,
                fundAccount: userInfo.clientId,
                actionType: actionType.value
              }
            }).then((res: any) => {
              const { code, message } = res
              if (code === 0) {
                console.log('message', message)
              } else {
                Message({ direction: 'vertical', text: '请求异常，请稍后重试！' })
              }
            })
            type.value = openType
            const params = {
              formType: 'openAccount',
              meta: 'open_common',
              content: JSON.stringify({ openType })
            }
            useSaveOpenInfo(params).then(() => {
              const info = {
                openType: type.value
              }
              store.commit(types.OPEN_COMMON, { info })
              if (formTypeVal === 'openAccount') {
                router.push({ name: 'id-card' })
              }
              if (formTypeVal === 'openImmigrantAccount') {
                router.push({ name: 'account-type' })
              }
            })
          })
          .catch(() => {
            //
          })
      }
      type.value = openType
      const params = {
        formType: 'openAccount',
        meta: 'open_common',
        content: JSON.stringify({ openType })
      }
      useSaveOpenInfo(params).then(() => {
        const info = {
          openType: type.value
        }
        store.commit(types.OPEN_COMMON, { info })
        if (formTypeVal === 'openAccount') {
          router.push({ name: 'id-card' })
        }
        if (formTypeVal === 'openImmigrantAccount') {
          router.push({ name: 'account-type' })
        }
      })
    }

    onMounted(async () => {
      setApptitle(title)
      const res = await queryFn('findDictOpenAccount', {
        markList: ['CUSTOMER_ACTION_TYPE']
      })
      console.log('res', res)
      actionType.value = res.data.CUSTOMER_ACTION_TYPE.filter(
        (item: any) => item.mark === 'CUSTOMER_ACTION_TYPE_1'
      )[0].value
    })
    return {
      handleClick,
      mainland,
      overseas,
      formType
    }
  }
})
